<template :key="componentKey">
  <div>
    <v-card elevation="6" class="pa-3 pb-8" height="700px">
      <v-subheader class="ma-0 pa-0">
        <h4 class="bold primary--text">Mensagens</h4>
      </v-subheader>
      <br />
      <v-row>
        <v-col cols="5">
          <v-card elevation="3" height="600px" class="rounded-lg">
            <v-tabs v-model="tab" centered>
              <v-tab> Chats ativos </v-tab>
              <v-tab> Chats em espera </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" :class="showScrolling">
                <v-tab-item>
                  <v-row v-for="(chat, i) in myChats" :key="i" class="ma-2" align="center">
                    <v-card
                      elevation="2"
                      @click="seeChat(chat.usuario_um_id, i)"
                      :class="[{'primary--text': i === activeIndex}]"
                      width="100%"
                    >
                      <v-row>
                        <v-col cols="8" class="d-flex flex-wrap">
                          <span class="w-full">{{ chat.usuario_um.nome }}</span>
                          <span class="w-full">{{ chat.usuario_um.descricao }}</span>
                        </v-col>
                        <v-col cols="4">
                          <span class="content-date">{{ chat.updated_at }}</span>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-row>
                </v-tab-item>
              <v-tab-item>
                <v-row v-for="(chat, i) in waitingChats" :key="i" class="ma-2" align="center">
                  <v-card elevation="5" width="100%">
                    <v-row>
                      <v-col cols="7" class="d-flex flex-wrap">
                        <span class="w-full">{{ chat.usuario_um.nome }}</span>
                        <span class="w-full">{{ chat.usuario_um.descricao }}</span>
                      </v-col>
                      <v-col cols="5">
                        <v-btn
                          class="ml-2 mt-3"
                          color="primary"
                          outlined
                          rounded
                          small
                          @click="takeChat(chat.usuario_um_id)"
                          :loading="loadingTakeChat"
                        >
                          Assumir conversa
                          <v-icon color="primary" class="ml-2">mdi-chat-outline</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <span class="bold ml-1 mb-4">3</span>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
        <v-col cols="7">
          <v-card
            elevation="10"
            height="600px"
            class="rounded-lg"
            v-if="activeUser.id && showChat"
          >
            <SupportChat
              :key="chatKey"
              @setLoadingChat="setLoadingChat"
              @close-chat-fullscreen="closeChat"
            ></SupportChat>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import SupportChat from '@/components/Chat/SupportChat.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    SupportChat
  },
  data () {
    return {
      waitingChats: [],
      showChat: false,
      chatKey: 0,
      loadingChat: false,
      tab: 0,
      loadingTakeChat: false,
      activeIndex: null,
      componentKey: 0
    }
  },
  computed: {
    ...mapGetters({
      myChats: 'chat/getMyChats',
      hasNewNotification: 'chat/getHasNewNotification'
    }),
    activeUser () {
      return this.$store.getters.activeUser
    },
    showScrolling () {
      return this.myChats.length > 5 ? 'chat-list-scroll-on' : 'chat-list-scroll-off'
    }
  },
  watch: {
    activeUser (newVal) {
      if (newVal) {
        this.getWaitingChats()
        this.getMyChats()
      }
    }
  },
  created () {
    if (this.activeUser && this.activeUser.id) {
      this.getWaitingChats()
      this.getMyChats()
    }
  },
  methods: {
    forceRerender () {
      this.componentKey += 1
    },
    getWaitingChats () {
      this.$store.dispatch('chat/waitingChats').then((resp) => {
        this.waitingChats = resp.data
      })
    },
    getMyChats () {
      this.$store.dispatch('chat/myChats')
    },
    takeChat (usuarioOrigemId) {
      this.loadingTakeChat = true
      this.$store
        .dispatch('chat/takeChat', usuarioOrigemId)
        .then(() => {
          this.getWaitingChats()
          this.getMyChats()
        })
        .finally(() => {
          this.loadingTakeChat = false
          this.$forceUpdate()
        })
    },
    seeChat (_usuarioOrigemId, i) {
      this.activeIndex = i
      this.setLoadingChat(true)
      this.$store.commit('chat/setUsuarioDestinoId', _usuarioOrigemId)
      this.$store.commit('chat/setUsuarioDestinoNome', null)
      this.chatKey++
      this.showChat = true
    },
    setLoadingChat (_loadingChat) {
      this.loadingChat = _loadingChat
    },
    closeChat () {
      this.showChat = false
    }
  }
}
</script>

<style>
.w-full {
  width: 100%;
  margin-left: 12px;
}
.content-date {
    white-space: pre-wrap;
    font-weight: 100;
    font-size: 14px;
    line-height: 1;
    margin-right: 5;
    margin-top: 5;
    padding-right: 2;
}
.chat-list-scroll-on {
  height: 90%;
  overflow-y: auto;
  background-size: 100%;
  padding-top: 2;
}
.chat-list-scroll-off {
  height: 90%;
  overflow-y: hidden;
  background-size: 100%;
  padding-top: 2;
}
</style>
